
import { defineComponent } from "vue";
import NotificationWrapper from "@/ag-portal-common/components/NotificationWrapper.vue";
import AuthenticationWrapper from "@/ag-portal-common/components/AuthWrapper.vue";
import Mitt from "mitt";
import { AUTH_EVENTS } from "@/ag-portal-common/eventBusses/auth";
import { NOTIFICATION_EVENTS } from "@/ag-portal-common/eventBusses/notification";

export default defineComponent({
  name: "AppView",
  components: {
    AuthenticationWrapper,
    NotificationWrapper,
  },

  beforeUnmount() {
    const mitt = Mitt();
    mitt.off(NOTIFICATION_EVENTS.TRIGGER);
    mitt.off(AUTH_EVENTS.LOGIN);
    mitt.off(AUTH_EVENTS.LOGOUT);
  },
});
