import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex justify-content-between align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ag_card = _resolveComponent("ag-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ag_card, { style: {"margin-left":"10%","margin-right":"10%"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ag_heading, {
            title: "Umrah Hotels",
            variant: "h2",
            style: {"margin-bottom":"0"}
          }),
          _createVNode(_component_router_link, { to: "/fit/umrah-hotels" }, {
            default: _withCtx(() => [
              _createVNode(_component_AGButton, { variant: "primary" }, {
                default: _withCtx(() => [
                  _createTextVNode("Explore")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}