import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgSelectedFlightInfo = _resolveComponent("AgSelectedFlightInfo")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_TripSummary = _resolveComponent("TripSummary")!
  const _component_HotelSummary = _resolveComponent("HotelSummary")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_PriceSummary = _resolveComponent("PriceSummary")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_ag_not_found = _resolveComponent("ag-not-found")!

  return (_ctx.isFetchingFDGBookingDetail)
    ? (_openBlock(), _createBlock(_component_ag_loader, { key: 0 }))
    : (!_ctx.isFetchingFDGBookingDetail && _ctx.fdgBookingDetail)
      ? (_openBlock(), _createBlock(_component_AgRow, {
          key: 1,
          "test-id": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AgColumn, {
              lg: "9",
              md: "8",
              sm: "12",
              "test-id": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AgCard, { "test-id": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AgRow, {
                      "test-id": "",
                      class: "v-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AgColumn, {
                          "test-id": "",
                          md: "9"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AgSelectedFlightInfo, null, {
                              pnrInfo: _withCtx(() => [
                                _createVNode(_component_AgDiv, { class: "booking_id_wrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Booking ID: "),
                                    _createElementVNode("span", null, _toDisplayString(_ctx.renderBookingId), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_AgColumn, {
                          "test-id": "",
                          md: "3",
                          class: "text-right"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AGButton, {
                              "test-id": "",
                              type: "button",
                              variant: _ctx.renderStatusSeverity
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.renderBookingStatus), 1)
                              ]),
                              _: 1
                            }, 8, ["variant"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLegs, (leg, index) => {
                  return (_openBlock(), _createBlock(_component_ag_div, { key: index }, {
                    default: _withCtx(() => [
                      (leg.itemType === _ctx.BOOKING_TYPES.FLIGHT)
                        ? (_openBlock(), _createBlock(_component_TripSummary, {
                            key: 0,
                            leg: leg
                          }, null, 8, ["leg"]))
                        : _createCommentVNode("", true),
                      (leg.itemType === _ctx.BOOKING_TYPES.HOTEL)
                        ? (_openBlock(), _createBlock(_component_HotelSummary, {
                            key: 1,
                            leg: leg
                          }, null, 8, ["leg"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_AgColumn, {
              lg: "3",
              md: "4",
              "test-id": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_PriceSummary, { priceInfoCells: _ctx.getPriceInfo }, null, 8, ["priceInfoCells"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_ag_not_found, { key: 2 }))
}