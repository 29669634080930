
import { defineComponent } from "vue";
import { PATH } from "@/ag-portal-common/constants/path";

export default defineComponent({
  name: "fitMainPage",
  setup() {
    return {};
  },
});
