
import { defineComponent } from "vue";
import qs from "query-string";
import PriceSummary from "@/modules/FDG/components/PriceSummary.vue";
import { PAX_MAPPINGS } from "@/enums/PAX_MAPPINGS";
import { PAX_TYPES } from "@/ag-portal-common/constants/paxTypes";
import {
  tourBookingValidationSchema,
  tourBookingWithoutDataValidationSchema,
} from "@/modules/FDG/validations";
import { ValidationError } from "yup";
import {
  formatNumber,
  formatWordIntoCapitalize,
  getFormattedDateTime,
  yupValidationErrorAsSchema,
} from "@/ag-portal-common/utils/helpers";
import NATIONALITIES from "@/ag-portal-common/constants/nationalities";
import { PAX_RELATIONS } from "@/ag-portal-common/constants/paxRelations";
import { PAX_TITLES } from "@/ag-portal-common/constants/paxTitles";
import {
  FORMAT_DD_MMM,
  FORMAT_YYY_MM_DD,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { FDGBookingConfirmRequestModel } from "@/modules/FDG/models/fdgBookingConfirm.request";
import { PATH } from "@/ag-portal-common/constants/path";
import { FDGBookingConfirmWithoutTravelerDetailsRequestModel } from "@/modules/FDG/models/fdgBookingConfirmWithoutTravelerDetails.request";
import {
  IFDGBookingDetail,
  IFDGBookingDetailItem,
} from "@/interfaces/fdgBookingDetail.interface";
import { IPriceSummary } from "@/modules/FDG/interface/priceSummary.interface";
import TripSummary from "@/modules/FDG/components/TripSummary.vue";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import HotelSummary from "@/modules/FDG/components/HotelSummary.vue";

const travelerInitialValues = {
  passportNumber: "",
  isPassportImageRequired: false,
  passportImage: null,
  passportRequired: false,
  passportExpiry: null,
  dob: null,
  title: null,
  firstName: "",
  lastName: "",
  type: "",
  documents: [],
  nationality: null,
  passengerRelation: null,
};

interface IFileDocument {
  title: string;
  file: File;
}

export default defineComponent({
  name: "BookingConfirmationForm",
  computed: {
    isFetchingFDGBookingDetail(): boolean {
      return this.$store.getters.isFetchingFDGBookingDetail;
    },
    BOOKING_TYPES() {
      return BOOKING_TYPES;
    },
    TOUR_TYPES() {
      return TOUR_TYPES;
    },
    PAX_RELATIONS() {
      return PAX_RELATIONS;
    },
    fdgBookingDetail(): IFDGBookingDetail {
      return this.$store.getters.fdgBookingDetail;
    },
    isConfirmFDGBooking() {
      return this.$store.getters.isConfirmFDGBooking;
    },
    isConfirmFDGBookingWithoutTravelerDetails() {
      return this.$store.getters.isConfirmFDGBookingWithoutTravelerDetails;
    },
    PAX_TYPES() {
      return PAX_TYPES;
    },
    NATIONALITIES() {
      return NATIONALITIES;
    },
    getLegs(): IFDGBookingDetailItem[] {
      let fdgBookingDetail: IFDGBookingDetail =
        this.$store.getters.fdgBookingDetail;
      return fdgBookingDetail?.items || [];
    },
    getPriceInfo(): IPriceSummary[] {
      let fdgBookingDetail: IFDGBookingDetail | null =
        this.$store.getters.fdgBookingDetail;
      let startDate = fdgBookingDetail?.startDate || new Date();

      return [
        {
          info: `${getFormattedDateTime(startDate, FORMAT_DD_MMM)} | ${
            fdgBookingDetail?.items[0].itemName
          }`,
          amount: `${fdgBookingDetail?.currency} ${formatNumber(
            fdgBookingDetail?.bookingPricePerPerson || ""
          )}`,
          isTotalPriceCell: false,
        },
        {
          info: `Passenger(s) Count x ${fdgBookingDetail?.numberOfGuests}`,
          amount: `${fdgBookingDetail?.currency} ${formatNumber(
            fdgBookingDetail?.totalPrice || ""
          )}`,
          isTotalPriceCell: false,
        },
        {
          info: "PRICE YOU PAY",
          amount: `${fdgBookingDetail?.currency} ${formatNumber(
            fdgBookingDetail?.totalPrice || ""
          )}`,
          isTotalPriceCell: true,
        },
      ];
    },
  },
  components: { HotelSummary, TripSummary, PriceSummary },
  data(): {
    formData: any;
    errors: any;
    enableFormFilling: boolean;
  } {
    return {
      enableFormFilling: false,
      errors: {},
      formData: {
        tourType: TOUR_TYPES.FLIGHTS_ONLY,
        bookingCustomer: {
          phone: "",
          email: "",
        },
        passengers: [],
      },
    };
  },
  methods: {
    formatWordIntoCapitalize,
    PAX_TITLES(type: PAX_MAPPINGS) {
      return PAX_TITLES.filter((item) => item.type === (type as string));
    },
    formattedDate(date: Date | null) {
      if (date) {
        return getFormattedDateTime(date, FORMAT_YYY_MM_DD);
      }
      return "";
    },
    async submitFormWithoutTravelerDetails() {
      this.errors = {};
      try {
        await tourBookingWithoutDataValidationSchema.validate(this.formData, {
          abortEarly: false,
        });
        let { fdgBookingDetail } = this.$store.getters;
        let payload: FDGBookingConfirmWithoutTravelerDetailsRequestModel = {
          booking_id: fdgBookingDetail.bookingId,
          contact_information: {
            contact_number: this.formData.bookingCustomer.phone,
            contact_email: this.formData.bookingCustomer.email,
          },
          no_of_passengers: fdgBookingDetail.numberOfGuests,
        };
        this.$store.dispatch(
          "confirmGroupFlightBookingWithoutTravelerDetails",
          {
            payload: payload,
            callback: () => this.$router.push(PATH.FDG_FLIGHTS),
          }
        );
      } catch (ex) {
        if (ex instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(ex);
        }
      }
    },

    async submitForm(e: { preventDefault: () => void }) {
      e.preventDefault();
      this.errors = {};
      try {
        await tourBookingValidationSchema.validate(this.formData, {
          abortEarly: false,
        });
        let { fdgBookingDetail } = this.$store.getters;
        let _formData = new FormData();
        for (const passenger of this.formData.passengers) {
          if (
            passenger.passportRequired &&
            this.formData.tourType === TOUR_TYPES.UMRAH
          ) {
            _formData.append(
              `${passenger.passportNumber}_passport_image`,
              passenger.passportImage
            );
          }
          for (let i = 0; i < passenger.documents.length; i++) {
            const document = passenger.documents[i];
            _formData.append(`${document.title}_${i}`, document.file);
          }
        }

        let payload: FDGBookingConfirmRequestModel = {
          booking_id: fdgBookingDetail.bookingId,
          contact_information: {
            contact_number: this.formData.bookingCustomer.phone,
            contact_email: this.formData.bookingCustomer.email,
          },
          passenger_details: [],
        };

        payload.passenger_details = this.formData.passengers.map(
          (passenger: any) => ({
            salutation: passenger.title,
            first_name: passenger.firstName,
            last_name: passenger.lastName,
            passenger_type: passenger.type,
            nationality: passenger.nationality,
            passport_no: passenger.passportRequired
              ? passenger.passportNumber
              : null,
            passport_expiry: passenger.passportRequired
              ? this.formattedDate(passenger.passportExpiry)
              : null,
            dob: this.formattedDate(passenger.dob),
            passenger_relation: passenger.passengerRelation,
            passport_image: passenger.isPassportImageRequired
              ? `${passenger.passportNumber}_passport_image`
              : null,
            documents: passenger.documents.map(
              (doc: IFileDocument, index: number) => ({
                title: doc.title,
                file: `${doc.title}_${index}`,
              })
            ),
          })
        );
        _formData.append("json_data", JSON.stringify(payload));
        this.$store.dispatch("confirmGroupFlightBooking", {
          payload: _formData,
          callback: () =>
            this.$router.push(
              fdgBookingDetail.tourType === TOUR_TYPES.FLIGHTS_ONLY
                ? PATH.FDG_FLIGHTS
                : PATH.FDG_UMRAH
            ),
        });
      } catch (ex) {
        if (ex instanceof ValidationError) {
          this.errors = yupValidationErrorAsSchema(ex);
        }
      }
    },
  },
  async beforeMount() {
    await this.$store.dispatch(
      "fetchGroupFlightBookingDetail",
      this.$route.params.id
    );
    let fdgBookingDetail: IFDGBookingDetail =
      this.$store.getters.fdgBookingDetail;
    this.enableFormFilling =
      fdgBookingDetail?.tourType !== TOUR_TYPES.FLIGHTS_ONLY;
    let parsedQuery = qs.parse(window.location.search) as {
      adult: string;
      infant: string;
      child: string;
    };
    let stringedZero = "0";
    let adultCount = parseInt(parsedQuery?.adult || stringedZero);
    let childCount = parseInt(parsedQuery?.child || stringedZero);
    let infantCount = parseInt(parsedQuery?.infant || stringedZero);
    let totalCount = adultCount + childCount + infantCount;
    let passengers: Array<any> = [];
    for (let i = 0; i < totalCount; i++) {
      let paxType = "";
      if (adultCount) {
        paxType = PAX_MAPPINGS.ADULT;
        adultCount--;
      } else if (childCount) {
        paxType = PAX_MAPPINGS.CHILD;
        childCount--;
      } else {
        paxType = PAX_MAPPINGS.INFANT;
        infantCount--;
      }
      let passenger = {
        ...travelerInitialValues,
        type: paxType,
        passportRequired: fdgBookingDetail?.passportRequired || false,
        isPassportImageRequired:
          fdgBookingDetail?.passportRequired &&
          fdgBookingDetail.tourType === TOUR_TYPES.UMRAH,
        documents:
          fdgBookingDetail?.additionalDocuments.map((doc: IFileDocument) => ({
            title: doc,
            file: null,
          })) || [],
      };
      passengers.push(passenger);
    }
    this.formData.tourType = fdgBookingDetail.tourType;
    this.formData.passengers = passengers;
  },
});
