import {
  IFDG,
  IFDGItem,
  IFlightItemDetails,
  IHotelItemDetails,
} from "@/interfaces/fdg.interface";
import {
  FDGItemDetails,
  GroupFlightsResponseModel,
} from "@/modules/FDG/models/fdgList.response";
import { GroupFlightDetailResponseModel } from "@/modules/FDG/models/fdgDetail.response";
import { IFDGDetail } from "@/interfaces/fdgDetail.interface";
import { FDGBookingDetailResponseModel } from "@/modules/FDG/models/fdgBookingDetail.response";
import {
  IFDGBookingDetail,
  IFDGBookingDetailFlightItem,
  IFDGBookingDetailHotelItem,
  IFDGBookingDetailItem,
} from "@/interfaces/fdgBookingDetail.interface";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";

export class FdgMapper {
  private fdgList: IFDG[] = [];
  private fdgDetail: IFDGDetail = {
    bookingInfo: null,
    bookingOptions: [],
    id: "",
    inclusions: [],
    items: [],
    mainImage: "",
    rules: [],
    title: "",
    type: "",
  };

  private fdgBookingDetail: IFDGBookingDetail = {
    status: "",
    bookingId: "",
    bookingPricePerPerson: 0,
    tourType: TOUR_TYPES.FLIGHTS_ONLY,
    items: [],
    passportRequired: false,
    additionalDocuments: [],
    numberOfGuests: 0,
    currency: "",
    startDate: new Date(),
    selectedFareId: "",
    totalPrice: 0,
  };

  public mapFDGListResponse(response: GroupFlightsResponseModel): IFDG[] {
    this.fdgList = response.data.map((item) => ({
      type: item.tour_type,
      id: item.tour_id,
      title: item.title,
      mainImage: item.main_image,
      bookingInfo: {
        id: item.booking_options[0].tour_option_id,
        remainingQuantity: item.booking_options[0].option_remaining_quantity,
        currency: item.booking_options[0].option_currency,
        price: item.booking_options[0].option_price,
        option_name: item.booking_options[0].option_name,
      },
      inclusions: item.inclusions.map((inclusion) => ({
        category: inclusion.category.name,
        icon: inclusion.category.icon,
        shortDescription: inclusion.short_description,
      })),
      items: item.items.map((item) => {
        const mappedItem: IFDGItem = {
          itemName: item.item_name,
          itemType: item.item_type as BOOKING_TYPES,
          flightItemDetails: null,
          hotelItemDetails: null,
        };
        if (item.item_type === BOOKING_TYPES.FLIGHT) {
          mappedItem.flightItemDetails = this.mapFlightItemDetails(
            item.item_details
          );
        }
        if (item.item_type === BOOKING_TYPES.HOTEL) {
          mappedItem.hotelItemDetails = this.mapHotelItemDetails(
            item.item_details
          );
        }
        return mappedItem;
      }),
    }));

    return this.fdgList;
  }

  public mapFDGDetailResponse(
    response: GroupFlightDetailResponseModel
  ): IFDGDetail {
    const { data } = response;
    this.fdgDetail.type = data.tour_type;
    this.fdgDetail.id = data.tour_id;
    this.fdgDetail.title = data.title;
    this.fdgDetail.mainImage = data.thumbnail[0]?.image || "";
    this.fdgDetail.rules = data.tour_rules;
    this.fdgDetail.bookingOptions = data.booking_options.map((item) => ({
      option_name: item.option_name,
      id: item.tour_option_id,
      remainingQuantity: item.option_remaining_quantity,
      currency: item.option_currency,
      price: item.option_price,
    }));
    this.fdgDetail.inclusions = data.inclusions.map((inclusion) => ({
      category: inclusion.category.name,
      icon: inclusion.category.icon,
      shortDescription: inclusion.short_description,
    }));
    this.fdgDetail.items = data.items.map((item) => {
      const mappedItem: IFDGItem = {
        itemName: item?.item_name,
        itemType: item?.item_type as BOOKING_TYPES,
        flightItemDetails: null,
        hotelItemDetails: null,
      };

      if (item.item_type === BOOKING_TYPES.FLIGHT) {
        mappedItem.flightItemDetails = this.mapFlightItemDetails(
          item.item_details
        );
      }
      if (item.item_type === BOOKING_TYPES.HOTEL) {
        mappedItem.hotelItemDetails = this.mapHotelItemDetails(
          item.item_details
        );
      }

      return mappedItem;
    });

    return this.fdgDetail;
  }

  public mapFDGBookingDetailResponse(
    response: FDGBookingDetailResponseModel
  ): IFDGBookingDetail {
    const { data } = response;
    this.fdgBookingDetail.status = data.status;
    this.fdgBookingDetail.bookingId = data.booking_id;
    this.fdgBookingDetail.selectedFareId =
      data.tour_details.selected_option.tour_option_id;
    this.fdgBookingDetail.totalPrice =
      data.tour_details.number_of_guest *
      data.tour_details.selected_option.option_price;
    this.fdgBookingDetail.bookingPricePerPerson =
      data.tour_details.selected_option.option_price;
    this.fdgBookingDetail.passportRequired =
      data.tour_details.passport_required;
    this.fdgBookingDetail.additionalDocuments =
      data.tour_details.additional_documents;
    this.fdgBookingDetail.numberOfGuests = data.tour_details.number_of_guest;
    this.fdgBookingDetail.startDate = new Date(data.tour_details.start_date);
    this.fdgBookingDetail.currency =
      data.tour_details.selected_option.option_currency;
    this.fdgBookingDetail.tourType = data.tour_details.tour_type as TOUR_TYPES;
    this.fdgBookingDetail.items = data.tour_details.items.map((item) => {
      const mappedItem: IFDGBookingDetailItem = {
        itemType: item.item_type,
        itemName: item.item_name,
        flightItemDetails: null,
        hotelItemDetails: null,
      };
      if (item.item_type === BOOKING_TYPES.FLIGHT) {
        const flightItemDetails: IFDGBookingDetailFlightItem = {
          airlineLogo: item.item_details?.segment?.length
            ? item.item_details?.segment[0].marketing_airline?.logo
            : "",
          flightNumber: item.item_details?.segment?.length
            ? item.item_details?.segment[0].flight_number[0]
            : "",
          departureDatetime: new Date(
            item.item_details?.departure_datetime || Date.now()
          ),
          arrivalDatetime: new Date(
            item.item_details?.arrival_datetime || Date.now()
          ),
          originCity: item.item_details?.origin?.city || "",
          destinationCity: item.item_details?.destination?.city || "",
          originIATACode: item.item_details?.origin?.iata_code || "",
          destinationIATACode: item.item_details?.destination?.iata_code || "",
        };
        mappedItem.flightItemDetails = flightItemDetails;
      } else if (item.item_type === BOOKING_TYPES.HOTEL) {
        const hotelItemDetails: IFDGBookingDetailHotelItem = {
          checkIn: new Date(item.item_details?.checkin || Date.now()),
          checkOut: new Date(item.item_details?.checkout || Date.now()),
          propertyName: item.item_details?.property?.name || "",
          propertyImage: item.item_details?.property?.main_image_url || "",
          distance: `${item.item_details?.distance?.value || 0} ${
            item.item_details?.distance?.unit || ""
          }`,
          propertyAddress: item.item_details?.property?.address || "",
        };
        mappedItem.hotelItemDetails = hotelItemDetails;
      }

      return mappedItem;
    });

    return this.fdgBookingDetail;
  }

  private mapFlightItemDetails(
    itemDetails: FDGItemDetails
  ): IFlightItemDetails {
    return {
      departureDatetime: new Date(
        itemDetails?.departure_datetime || Date.now()
      ),
      arrivalDatetime: new Date(itemDetails?.arrival_datetime || Date.now()),
      originCity: itemDetails?.origin?.city || "",
      destinationCity: itemDetails?.destination?.city || "",
      originIATACode: itemDetails?.origin?.iata_code || "",
      destinationIATACode: itemDetails?.destination?.iata_code || " ",
      flightDuration: itemDetails?.duration_minutes || 0,
      baggagePieces: itemDetails?.baggage_info?.pieces || 0,
      baggageWeight: itemDetails?.baggage_info?.weight || 0,
      baggageUnit: itemDetails?.baggage_info?.unit || "",
      segment:
        itemDetails?.segment?.map((item) => ({
          rbd: item.rbd,
          origin: {
            city: item.origin.city,
            name: item.origin.name,
            airport: item.origin.airport,
            country: item.origin.country,
            iataCode: item.origin.iata_code,
            timeZone: item.origin.time_zone,
          },
          destination: {
            city: item.destination.city,
            name: item.destination.name,
            airport: item.destination.airport,
            country: item.destination.country,
            iataCode: item.destination.iata_code,
            timeZone: item.destination.time_zone,
          },
          airline: item?.marketing_airline,
          isStop: item?.is_stop,
          flightLeg: item?.flight_leg,
          cabinClass: item?.cabin_class,
          flightNumber: item?.flight_number[0] || "",
          arrivalDatetime: new Date(item?.arrival_datetime),
          departureDatetime: new Date(item?.departure_datetime),
          duration: item?.duration_minutes,
        })) || [],
    };
  }

  private mapHotelItemDetails(itemDetails: FDGItemDetails): IHotelItemDetails {
    return {
      checkIn: new Date(itemDetails?.checkin || Date.now()),
      checkOut: new Date(itemDetails?.checkout || Date.now()),
      propertyName: itemDetails?.property?.name || "",
      propertyCity: itemDetails?.property?.city || "",
      propertyCountry: itemDetails?.property?.country || "",
      propertyAddress: itemDetails?.property?.address || "",
      distance: `${itemDetails?.distance?.value || 0} ${
        itemDetails?.distance?.unit || ""
      }`,
      facilities: itemDetails?.property?.facilities || [],
      images: itemDetails?.property?.images || [],
    };
  }
}
