
import { defineComponent, PropType } from "vue";
import { IFDGBookingDetailItem } from "@/interfaces/fdgBookingDetail.interface";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_DD_MMM_YYYY,
  FORMAT_HH_SS,
} from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "TripSummary",
  props: {
    leg: {
      type: Object as PropType<IFDGBookingDetailItem>,
    },
  },
  computed: {
    FORMAT_HH_SS() {
      return FORMAT_HH_SS;
    },
  },
  methods: {
    renderDate: (date: Date, format = FORMAT_DD_MMM_YYYY) => {
      return date ? getFormattedDateTime(date, format) : "";
    },
  },
});
