
import { defineComponent } from "vue";
import FlightItenary from "@/modules/FDG/components/FlightItenary.vue";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import {
  formatNumber,
  formatStringToRoutePath,
} from "@/ag-portal-common/utils/helpers";
import { FDGBookingDetailRequestModel } from "@/modules/FDG/models/fdgBookingDetail.request";
import { IFDGDetail } from "@/interfaces/fdgDetail.interface";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_STYLES } from "@/ag-portal-common/enums/NOTIFICATION_STYLES";
import { PATH } from "@/ag-portal-common/constants/path";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import HotelItenary from "@/modules/FDG/components/HotelItenary.vue";

export default defineComponent({
  name: "TourDetail",
  props: {
    paxSelectionHeading: {
      type: String,
      default: "",
    },
    paxSelectionDescription: {
      type: String,
      default: "",
    },
  },
  methods: {
    formatNumber,
    updateAdultCount(count: number) {
      this.adultCount = count;
    },
    updateChildCount(count: number) {
      this.childCount = count;
    },
    updateInfantCount(count: number) {
      this.infantCount = count;
    },
    routeToBookingConfirmation(id: string) {
      let url = PATH.FDG_FLIGHT_BOOKING_CONFIRMATION;
      url += `?adult=${this.adultCount}`;
      url += `&child=${this.childCount}`;
      url += `&infant=${this.infantCount}`;

      this.$router.push(formatStringToRoutePath(url, { id }));
    },
    onBookGroup() {
      const { adultCount, childCount, infantCount } = this;

      if (!this.selectedBookingId) {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.description =
          NOTIFICATION_MESSAGES.SELECT_ANY_PACKAGE;
        notificationService.triggerNotification();
        return;
      }

      let payload: FDGBookingDetailRequestModel = {
        no_of_passengers: infantCount + adultCount + childCount,
        tour_option_id: this.selectedBookingId,
      };

      this.$store.dispatch("initiateGroupFlightBooking", {
        payload,
        callback: this.routeToBookingConfirmation,
      });
    },
  },
  components: { HotelItenary, FlightItenary },
  beforeMount() {
    this.$store.dispatch("fetchGroupFlightDetail", this.$route.params.id);
  },
  data() {
    return {
      selectedBookingId: "",
      adultCount: 1,
      childCount: 0,
      infantCount: 0,
    };
  },
  computed: {
    BOOKING_TYPES() {
      return BOOKING_TYPES;
    },
    NOTIFICATION_MESSAGES() {
      return NOTIFICATION_MESSAGES;
    },
    fdgDetail(): IFDGDetail {
      return this.$store.getters.fdgDetail;
    },
    isFetchingFDGDetail(): boolean {
      return this.$store.getters.isFetchingFDGDetail;
    },
    isInitiatingFDGBooking(): boolean {
      return this.$store.getters.isInitiatingFDGBooking;
    },
  },
});
