
import { defineComponent, PropType } from "vue";
import { IFDG } from "@/interfaces/fdg.interface";
import { IFDGBookingInfo } from "@/interfaces/fdg.interface";
import {
  formatNumber,
  formatStringToRoutePath,
} from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";

export default defineComponent({
  name: "ToursList",
  props: {
    tourType: {
      type: String as PropType<TOUR_TYPES>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedCurrencies: [] as string[],
      selectedAirlines: [] as string[],
      selectedCities: [] as string[],
      selectedMonths: [] as string[],
      newLoad: true,
    };
  },
  methods: {
    isUmrah() {
      return this.tourType === TOUR_TYPES.UMRAH;
    },
    routToDetailPage(id: string) {
      let routePath =
        this.tourType === TOUR_TYPES.FLIGHTS_ONLY
          ? PATH.FDG_FLIGHT_DETAIL
          : this.tourType === TOUR_TYPES.UMRAH
          ? PATH.FDG_UMRAH_DETAIL
          : "";
      return formatStringToRoutePath(routePath, { id });
    },
    formatNumber,
  },
  beforeMount() {
    this.$store.dispatch("fetchGroupFlights", this.tourType);
  },
  computed: {
    TOUR_TYPES() {
      return TOUR_TYPES;
    },
    NOTIFICATION_MESSAGES() {
      return NOTIFICATION_MESSAGES;
    },
    filteredFDGlist(): IFDG[] {
      let filteredList = this.fdgList;
      if (this.newLoad === true) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedCurrencies = this.uniqueCurrencies;

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAirlines = this.uniqueAirlines;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedCities = this.uniqueCities;

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedMonths = this.uniqueMonths;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.newLoad = false;
      filteredList = filteredList.filter((fdg) =>
        this.selectedCurrencies.includes(fdg.bookingInfo.currency)
      );

      filteredList = filteredList.filter((fdg) =>
        this.selectedAirlines.includes(
          fdg.items[0].flightItemDetails?.segment[0].airline.name || ""
        )
      );

      filteredList = filteredList.filter((fdg) =>
        this.selectedCities.includes(
          fdg.items[0].flightItemDetails?.originCity || ""
        )
      );

      filteredList = filteredList.filter((fdg) =>
        this.selectedMonths.includes(
          fdg.items[0].flightItemDetails?.segment[0].departureDatetime?.toLocaleString(
            "default",
            { month: "long", year: "numeric" }
          ) || ""
        )
      );
      return filteredList;
    },
    fdgList(): IFDG[] {
      return this.$store.getters.fdgList;
    },
    finalList() {
      if (this.isUmrah()) {
        return this.fdgList;
      } else {
        return this.filteredFDGlist;
      }
    },
    fdgBookingDetail(): IFDGBookingInfo {
      return this.$store.getters.fdgBookingDetail;
    },
    isFetchingFDGList(): boolean {
      return this.$store.getters.isFetchingFDGList;
    },

    uniqueCurrencies(): string[] {
      const currencies = this.fdgList.map((fdg) => fdg.bookingInfo.currency);
      return [...new Set(currencies)];
    },
    uniqueAirlines(): string[] {
      const airlines = this.fdgList.map(
        (fdg: IFDG) =>
          fdg.items[0].flightItemDetails?.segment[0].airline.name || ""
      );
      return [...new Set(airlines)];
    },
    uniqueCities(): string[] {
      const cities = this.fdgList.map(
        (fdg: IFDG) => fdg.items[0].flightItemDetails?.originCity || ""
      );
      return [...new Set(cities)];
    },
    uniqueMonths(): string[] {
      const today = new Date();
      const sixMonthsLater = new Date();
      sixMonthsLater.setMonth(today.getMonth() + 6);

      const uniqueMonths: Set<string> = new Set();

      while (today <= sixMonthsLater) {
        const monthYearString = `${today.toLocaleString("default", {
          month: "long",
          year: "numeric",
        })}`;
        uniqueMonths.add(monthYearString);
        today.setMonth(today.getMonth() + 1);
      }

      return Array.from(uniqueMonths);
    },
  },
});
