
import { defineComponent } from "vue";
import { format } from "date-fns";
import _ from "lodash";

import {
  popupAlertBus,
  POPUP_ALERT_EVENTS,
} from "../eventBusses/popupAlertBus";

export default defineComponent({
  name: "AgPopupAlert",
  data() {
    return {
      isOpen: false,
      title: "",
      description: "",
      alertType: "error",
    };
  },
  computed: {
    renderDate() {
      return format(new Date(), "yyyy-MM-dd HH:mm");
    },
  },
  methods: {
    handleClose() {
      this.isOpen = false;
    },
  },
  mounted() {
    popupAlertBus.on(POPUP_ALERT_EVENTS.POPUP_ALERT, (payload: any) => {
      this.isOpen = true;
      this.title = payload?.title
        ? payload?.title
        : payload?.type
        ? _.capitalize(payload?.type)
        : "error";
      this.description = payload?.message;
      this.alertType = payload?.type ? payload?.type : "error";
    });
  },
  unmounted() {
    popupAlertBus.off(POPUP_ALERT_EVENTS.POPUP_ALERT);
  },
});
