import RestClientService from "@/ag-portal-common/services/restClient.service";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { GroupFlightsResponseModel } from "@/modules/FDG/models/fdgList.response";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_STYLES } from "@/ag-portal-common/enums/NOTIFICATION_STYLES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import { formatString } from "@/ag-portal-common/utils/helpers";
import { GroupFlightDetailResponseModel } from "@/modules/FDG/models/fdgDetail.response";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";
import { FIT_API_PATH } from "../constants";
import { PATH } from "@/ag-portal-common/constants/path";

class FITService {
  private restClient: RestClientService;
  $router: any;

  constructor() {
    this.restClient = new RestClientService();
  }

  public async fitSendEmailRequest(payload: any) {
    const methodName = "FITService.fitSendEmailRequest";
    let response = null;

    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

      // Assuming this is where you intend to use the payload for sending email requests
      response = await this.restClient.post(FIT_API_PATH.SEND_EMAIL, payload);

      if (response.success && response.data) {
        notificationService.type = NOTIFICATION_TYPES.SUCCESS;
        notificationService.description = "Successfully sent email";
        notificationService.triggerNotification();
        localStorage.removeItem("itenary");
      } else {
        // Handle error in email request if needed
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || "Something went wrong";
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description = "something went wrong";
      notificationService.triggerNotification();
    }

    return response;
  }

  public async fetchFitHotel(
    city: string,
    checkin: string,
    checkout: string,
    travelers: number
  ) {
    const methodName = "FITService.fetchFitHotel";
    let response = null;
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      response = await this.restClient.get(
        `${FIT_API_PATH.GET_HOTELS}city_id=${city}&check_in=${checkin}&check_out=${checkout}&travelers=${travelers}`
      );
      if (response.success && response.data) {
        return response;
      } else {
        notificationService.notificationStyle = NOTIFICATION_STYLES.SNACKBAR;
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return response;
  }
}

export default FITService;
