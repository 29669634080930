import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeLayout from "@/ag-portal-common/Layout/HomeLayout.vue";
import { PATH } from "@/ag-portal-common/constants/path";
import HomeRoutes from "@/router/homeRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.FIX_DEPARTURE_GROUP,
    name: "homeRoutes",
    component: HomeLayout,
    meta: {
      isProtected: true,
    },
    props: {
      title: "",
    },
    children: HomeRoutes,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0); // Scroll to top
  next();
});

export default router;
