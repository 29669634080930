import { SUPPORTED_FORMATS } from "@/ag-portal-common/constants/imageSupportedFormats";
import * as yup from "yup";
import { PAX_TITLES } from "@/ag-portal-common/constants/paxTitles";
import NATIONALITIES from "@/ag-portal-common/constants/nationalities";
import { addYears, isAfter, isBefore, subMonths, subYears } from "date-fns";
import { PAX_RELATIONS } from "@/ag-portal-common/constants/paxRelations";
import { PAX_MAPPINGS } from "@/enums/PAX_MAPPINGS";

const FILE_SIZE = 5000000;
export const imageValidation = (label: string) =>
  yup
    .mixed()
    .nullable()
    .required(`${label} is required`)
    .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value: any) => !value || (value && value.size <= FILE_SIZE)
    )
    .test(
      "FILE_FORMAT",
      "Uploaded file has unsupported format.",
      (value: any) =>
        !value || (value && SUPPORTED_FORMATS.includes(value.type))
    );

export const tourBookingValidationSchema = yup.object().shape({
  bookingCustomer: yup.object().shape({
    phone: yup.string().required("Phone number is required"),
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email is required"),
  }),
  passengers: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .required("Title is required")
        .oneOf(
          PAX_TITLES.map((title) => title.value),
          "Title is required"
        )
        .nullable(),
      passengerRelation: yup
        .string()
        .required("Passenger relation is required")
        .oneOf(
          PAX_RELATIONS.map((title) => title.value),
          "Passenger relation is required"
        )
        .nullable(),
      firstName: yup
        .string()
        .min(2, "Please enter a valid First Name")
        .required("First Name is required"),
      lastName: yup
        .string()
        .min(2, "Please enter a valid Last Name")
        .required("Last Name is required"),
      dob: yup
        .date()
        .test(
          "required",
          "Adult age must be greater than or equal to 12 yrs",
          (value: Date | undefined, ctx) => {
            if (ctx.parent.type === PAX_MAPPINGS.ADULT) {
              const currentDate = new Date();
              const pastDate12YearsOld = subYears(currentDate, 12);
              const targetDate = value ? new Date(value) : new Date();
              return isBefore(targetDate, pastDate12YearsOld);
            }
            return true;
          }
        )
        .test(
          "required",
          "Child age must be greater than 2 and less than or equal to 11yrs",
          (value: Date | undefined, ctx) => {
            if (ctx.parent.type === PAX_MAPPINGS.CHILD) {
              const currentDate = new Date();
              const pastDate11YearsOld = subYears(currentDate, 11);
              const pastDate2YearsOld = subYears(currentDate, 2);
              const targetDate = value || new Date();
              return (
                isBefore(targetDate, pastDate2YearsOld) &&
                isAfter(targetDate, pastDate11YearsOld)
              );
            }
            return true;
          }
        )
        .test(
          "required",
          "Infant age must be less than or equal to 23 months",
          (value, ctx) => {
            if (ctx.parent.type === PAX_MAPPINGS.INFANT) {
              const currentDate = new Date();
              const pastDate23MonthsOld = subMonths(currentDate, 23);
              const targetDate = value || new Date();

              return (
                isAfter(targetDate, pastDate23MonthsOld) &&
                isBefore(targetDate, currentDate)
              );
            }
            return true;
          }
        )
        .nullable(),
      nationality: yup
        .string()
        .oneOf(
          NATIONALITIES.map((nationality) => nationality.value),
          "Nationality is required"
        )
        .required("Nationality is required")
        .nullable(),
      passportExpiry: yup
        .date()
        .when("passportRequired", {
          is: true,
          then: yup
            .date()
            .test(
              "required",
              "Passport must be valid for an year",
              (value: Date | undefined, ctx) => {
                const currentDate = new Date();
                const futureDateAnYearOld = addYears(currentDate, 1);
                const targetDate = value || new Date();
                return isAfter(targetDate, futureDateAnYearOld);
              }
            )
            .min(new Date(), "Invalid date")
            .nullable(),
        })
        .nullable(),
      passportNumber: yup.string().when("passportRequired", {
        is: true,
        then: yup
          .string()
          .min(2, "Please enter a valid passport number")
          .required("Passport number is required"),
      }),
      passportImage: yup.mixed().when("isPassportImageRequired", {
        is: true,
        then: imageValidation("Passport image"),
      }),
      documents: yup.array().of(
        yup.object().shape({
          file: imageValidation(""),
        })
      ),
    })
  ),
});

export const tourBookingWithoutDataValidationSchema = yup.object().shape({
  bookingCustomer: yup.object().shape({
    phone: yup.string().required("Phone number is required"),
    email: yup
      .string()
      .email("Please enter valid email")
      .required("Email is required"),
  }),
});
