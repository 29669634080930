
import { defineComponent, inject } from "vue";
import { IAgentFinances } from "@/ag-portal-common/interfaces/agentFinances.interface";
import AgentFinanceService from "@/ag-portal-common/widgets/AgentFinancesWidget/services";
import {
  formatNumber,
  formatWordIntoCapitalize,
} from "@/ag-portal-common/utils/helpers";
import { AUTH_CONTEXT_KEYS } from "@/ag-portal-common/constants/authContextKeys";
import { IOrganizationFromLoginResponse } from "@/ag-portal-common/interfaces/organization.interface";
import {
  AGENT_FINANCES_EVENTS,
  agentFinanceBus,
} from "@/ag-portal-common/eventBusses/agentFinances";
import analyticsService from "@/ag-portal-common/services/local_analytics.service";
import { ANALYTICS_COMMON_EVENTS } from "@/ag-portal-common/constants/analytics";

export default defineComponent({
  name: "AgentFinancesWidget",
  props: {
    showStatus: {
      type: Boolean,
      default: false,
    },
  },
  data(): {
    agentFinances: IAgentFinances;
    isLoadingFinances: boolean;
  } {
    return {
      agentFinances: {
        currentBalance: 0,
        temporaryCreditLimit: 0,
        currentLimit: 0,
        availableCredit: 0,
        totalNotInvoicedTicket: 0,
        permanentCreditLimit: 0,
        currency: "",
        accountStatus: "",
        statusRemarks: "",
      },
      isLoadingFinances: false,
    };
  },
  computed: {
    isAccountActive() {
      return this.accountStatus.toLowerCase() === "active";
    },
    accountStatus() {
      return formatWordIntoCapitalize(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.agentFinances?.accountStatus.replaceAll("_", " ")
      );
    },
    statusRemarks() {
      return formatWordIntoCapitalize(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.agentFinances?.statusRemarks.replaceAll("_", " ")
      );
    },
  },
  methods: {
    formatWordIntoCapitalize,
    formattedAmount(amount: number) {
      const currency = localStorage.getItem("currency");
      return `${formatNumber(amount)} ` + currency;
    },
    async fetchAgentFinances(isFetchNewData?: boolean) {
      let organization =
        this.organization() as IOrganizationFromLoginResponse | null;
      this.isLoadingFinances = true;
      const agentFinancesService = new AgentFinanceService();
      this.agentFinances = await agentFinancesService.fetchFinancesById(
        organization?.financial_profiles[0].public_id || "",
        isFetchNewData || false
      );
      this.isLoadingFinances = false;
    },
    onClickFinancialStatusTrack() {
      const payload = {
        status: this.isAccountActive,
      };

      analyticsService.logActionEvent(
        ANALYTICS_COMMON_EVENTS.HEADER_ITEM_FINANCIAL_STATUS,
        payload
      );
    },
    onClickFinancialBreakdownTrack() {
      const payload = {
        "current-limit": this.formattedAmount(this.agentFinances.currentLimit),
        "current-balance": this.formattedAmount(
          this.agentFinances.currentBalance
        ),
        "permanent-credit-limit": this.formattedAmount(
          this.agentFinances.permanentCreditLimit
        ),
        "total-not-invoiced-ticket": this.formattedAmount(
          this.agentFinances.totalNotInvoicedTicket
        ),
        "available-credit": this.formattedAmount(
          this.agentFinances.availableCredit
        ),
        "temporary-credit-limit": this.formattedAmount(
          this.agentFinances.temporaryCreditLimit
        ),
      };

      analyticsService.logActionEvent(
        ANALYTICS_COMMON_EVENTS.HEADER_ITEM_FINANCIAL_BREAKDOWN,
        payload
      );
    },
    handleRefreshBalance() {
      this.fetchAgentFinances(true);
    },
  },
  created() {
    this.fetchAgentFinances();
    agentFinanceBus.on(AGENT_FINANCES_EVENTS.REFRESH, (isFetchNewData) => {
      this.fetchAgentFinances(isFetchNewData as boolean);
    });
  },
  setup() {
    let organization = inject(
      AUTH_CONTEXT_KEYS.organization
    ) as () => IOrganizationFromLoginResponse | null;
    return {
      organization,
    };
  },
});
