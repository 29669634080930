
import { defineComponent } from "vue";
import { INotification } from "@/ag-portal-common/interfaces/notification.interface";
import {
  NOTIFICATION_EVENTS,
  notificationBus,
} from "@/ag-portal-common/eventBusses/notification";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { genTestId } from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_STYLES } from "@/ag-portal-common/enums/NOTIFICATION_STYLES";
import NotificationPopup from "@/ag-portal-common/components/NotificationPopup.vue";

export default defineComponent({
  name: "NotificationWrapper",
  components: { NotificationPopup },
  computed: {
    NOTIFICATION_STYLES() {
      return NOTIFICATION_STYLES;
    },
    ELEMENT_TYPES() {
      return ELEMENT_TYPES;
    },
  },
  data() {
    return {
      show: false,
      notificationStyle: NOTIFICATION_STYLES.SNACKBAR,
      description: "",
      actionButtonText: "",
      title: "",
      type: NOTIFICATION_TYPES.INFO,
    };
  },
  methods: {
    genTestId,
    disable() {
      this.show = false;
    },
    action() {
      return;
    },
    triggerNotification(data: INotification) {
      this.show = false;
      this.description = data.description;
      this.type = data.type;
      this.show = true;
      this.title = data?.title || "";
      this.actionButtonText = data?.actionButtonText || "";
      this.notificationStyle =
        data?.notificationStyle || this.notificationStyle;
      this.action = data?.action || this.action;
    },
  },
  beforeMount() {
    notificationBus.on(NOTIFICATION_EVENTS.TRIGGER, (data) => {
      this.triggerNotification(data as INotification);
    });
  },
});
