import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { ActionContext, ActionTree } from "vuex";
import { IFDGState } from "@/interfaces/fdgState.interface";
import FDGService from "@/modules/FDG/services/fdg.service";
import { IFDG } from "@/interfaces/fdg.interface";
import { IFDGDetail } from "@/interfaces/fdgDetail.interface";
import { FDGBookingDetailRequestModel } from "@/modules/FDG/models/fdgBookingDetail.request";
import { IFDGBookingDetail } from "@/interfaces/fdgBookingDetail.interface";
import { FDGBookingConfirmRequestModel } from "@/modules/FDG/models/fdgBookingConfirm.request";
import { FDGBookingConfirmWithoutTravelerDetailsRequestModel } from "@/modules/FDG/models/fdgBookingConfirmWithoutTravelerDetails.request";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";

const actions: ActionTree<IFDGState, IFDGState> = {
  async fetchGroupFlights(
    context: ActionContext<IFDGState, IFDGState>,
    tourType: TOUR_TYPES
  ) {
    const methodName = "actions.fetchGroupFlights";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("enableIsFetchingGroupFlights", true);
    const fdgService = new FDGService();
    const response: IFDG[] = await fdgService.fetchGroupFlights(tourType);
    context.commit("saveGroupFlights", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
  },

  async fetchGroupFlightDetail(
    context: ActionContext<IFDGState, IFDGState>,
    id: string
  ) {
    const methodName = "actions.fetchGroupFlightDetail";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("enableIsFetchingGroupFlightDetail", true);
    const fdgService = new FDGService();
    const response: IFDGDetail | null = await fdgService.fetchGroupFlightDetail(
      id
    );
    context.commit("saveGroupFlightDetail", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
  },

  async initiateGroupFlightBooking(
    context: ActionContext<IFDGState, IFDGState>,
    {
      payload,
      callback,
    }: {
      payload: FDGBookingDetailRequestModel;
      callback: (bookingId: string) => void;
    }
  ) {
    const methodName = "actions.initiateBooking";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("enableIsInitiatingGroupFlightBooking", true);
    const fdgService = new FDGService();
    const response: IFDGBookingDetail | null = await fdgService.initiateBooking(
      payload,
      callback
    );
    context.commit("disableIsInitiatingGroupFlightBooking", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
  },

  async confirmGroupFlightBooking(
    context: ActionContext<IFDGState, IFDGState>,
    {
      payload,
      callback,
    }: {
      payload: FDGBookingConfirmRequestModel;
      callback: () => void;
    }
  ) {
    const methodName = "actions.confirmGroupFlightBooking";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("enableIsConfirmGroupFlightBooking");
    const fdgService = new FDGService();
    const response = await fdgService.confirmBooking(payload, callback);
    context.commit("disableIsConfirmGroupFlightBooking");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
  },

  async confirmGroupFlightBookingWithoutTravelerDetails(
    context: ActionContext<IFDGState, IFDGState>,
    {
      payload,
      callback,
    }: {
      payload: FDGBookingConfirmWithoutTravelerDetailsRequestModel;
      callback: () => void;
    }
  ) {
    const methodName = "actions.confirmGroupFlightBooking";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("enableIsConfirmGroupFlightBookingWithoutTravelerDetails");
    const fdgService = new FDGService();
    const response =
      await fdgService.confirmGroupFlightBookingWithoutTravelerDetails(
        payload,
        callback
      );
    context.commit("disableIsConfirmGroupFlightBookingWithoutTravelerDetails");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
  },

  async fetchGroupFlightBookingDetail(
    context: ActionContext<IFDGState, IFDGState>,
    bookingId: string
  ) {
    const methodName = "actions.fetchGroupFlightBooking";
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
    context.commit("enableIsFetchingGroupFlightBookingDetail", true);
    const fdgService = new FDGService();
    const response: IFDGBookingDetail | null =
      await fdgService.fetchGroupFlightBookingDetail(bookingId);
    context.commit("saveGroupFlightBookingDetail", response);
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`, response);
  },
};

export default actions;
