import { GetterTree } from "vuex";
import { IFDGState } from "@/interfaces/fdgState.interface";
import { IFDG } from "@/interfaces/fdg.interface";
import { IFDGDetail } from "@/interfaces/fdgDetail.interface";
import { IFDGBookingDetail } from "@/interfaces/fdgBookingDetail.interface";

const getters: GetterTree<IFDGState, IFDGState> = {
  isFetchingFDGList: (state): boolean => state.isFetchingFDGList,
  fdgList: (state): Array<IFDG> => state.fdgList,
  fdgDetail: (state): IFDGDetail | null => state.fdgDetail,
  isFetchingFDGDetail: (state): boolean => state.isFetchingFDGDetail,
  fdgBookingDetail: (state): IFDGBookingDetail | null => state.fdgBookingDetail,
  isFetchingFDGBookingDetail: (state): boolean =>
    state.isFetchingFDGBookingDetail,
  isInitiatingFDGBooking: (state): boolean => state.isInitiatingFDGBooking,
  isConfirmFDGBooking: (state): boolean => state.isConfirmFDGBooking,
  isConfirmFDGBookingWithoutTravelerDetails: (state): boolean =>
    state.isConfirmFDGBookingWithoutTravelerDetails,
};

export default getters;
