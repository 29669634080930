import { RouteRecordRaw } from "vue-router";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import fitMainPage from "@/modules/FIT/view/FitMainPage.vue"; // yet just for running rest will change
import HotelsAndUmrah from "@/modules/FIT/view/HotelsAndUmrah.vue"; // yet just for running rest will change
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "fit",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FIT.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FIT.PERMISSIONS,
    },
    component: fitMainPage, // yet just for running rest will change
  },
  {
    path: PATH.FIT_HOTELS,
    name: "umrah-hotels",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.FIT.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.FIT.PERMISSIONS,
    },
    component: HotelsAndUmrah, // yet just for running rest will change
  },
];

export default routes;
