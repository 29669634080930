
import { defineComponent } from "vue";
import TourDetail from "@/modules/FDG/components/TourDetail.vue";
import { FDG_DETAIL_VIEW_TEXTS } from "@/modules/FDG/constants";

export default defineComponent({
  name: "GroupFlightDetail",
  computed: {
    FDG_DETAIL_VIEW_TEXTS() {
      return FDG_DETAIL_VIEW_TEXTS;
    },
  },
  components: { TourDetail },
});
