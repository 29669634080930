
import { defineComponent } from "vue";
import { AUTH_EVENTS, authBus } from "@/ag-portal-common/eventBusses/auth";
import { LoginResponseModel } from "@/ag-portal-common/models/loginResponse.model";
import storageService from "@/ag-portal-common/services/storage.service";
import StorageService from "@/ag-portal-common/services/storage.service";
import {
  clearCache,
  parseLocalStorageData,
  stringEncrypt,
} from "@/ag-portal-common/utils/helpers";
import { STORAGE_KEYS } from "@/ag-portal-common/constants/storageKeys";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  IOrganizationFromLoginResponse,
  IOrganizationPreference,
} from "@/ag-portal-common/interfaces/organization.interface";
import { IUserV2 } from "@/ag-portal-common/interfaces/user.interface";
import { PERMISSIONS } from "@/ag-portal-common/enums/PERMISSIONS";
import { ISettings } from "@/ag-portal-common/interfaces/settings.interface";
import { IAuthState } from "@/ag-portal-common/interfaces/authState.interface";
import { setThemeColor } from "@/ag-portal-common/utils/colortheme";

export default defineComponent({
  name: "AuthenticationWrapper",
  data(): IAuthState & { organizationPreference: any } {
    return {
      user: null,
      settings: null,
      permissions: null,
      organization: null,
      organizationPreference: null,
      isUserSignedIn: false,
      isAuthenticating: false,
    };
  },
  created() {
    let permissions = parseLocalStorageData(
      STORAGE_KEYS.PERMISSIONS
    ) as Array<PERMISSIONS>;
    let user = parseLocalStorageData(STORAGE_KEYS.USER) as IUserV2;
    let accessToken = StorageService.getItem(
      STORAGE_KEYS.ACCESS_TOKEN
    ) as string;
    let refreshToken = StorageService.getItem(
      STORAGE_KEYS.REFRESH_TOKEN
    ) as string;
    let settings = parseLocalStorageData(STORAGE_KEYS.SETTINGS) as ISettings;
    let organization = parseLocalStorageData(
      STORAGE_KEYS.ORGANIZATION
    ) as IOrganizationFromLoginResponse;
    let organizationPreferences = parseLocalStorageData(
      STORAGE_KEYS.PREFERENCES
    ) as IOrganizationPreference;

    if (accessToken || refreshToken) {
      if (
        user &&
        permissions &&
        settings &&
        organization &&
        organizationPreferences
      ) {
        this.saveAuthInfo(
          user,
          permissions,
          organizationPreferences,
          settings,
          organization
        );
      } else {
        this.logout();
      }
    }
  },
  methods: {
    login(data: LoginResponseModel) {
      let user: IUserV2 = {
        ...data.user,
        bank_details: data?.organization?.financial_profiles
          ? data?.organization?.financial_profiles[0]?.bank_details
          : undefined,
      };
      storageService.setItem(STORAGE_KEYS.ACCESS_TOKEN, data.token.access);
      storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, data.token.refresh);
      storageService.setItem(
        STORAGE_KEYS.USER,
        stringEncrypt(JSON.stringify(user))
      );
      storageService.setItem(
        STORAGE_KEYS.PERMISSIONS,
        stringEncrypt(JSON.stringify(data.permissions))
      );
      storageService.setItem(
        STORAGE_KEYS.SETTINGS,
        stringEncrypt(JSON.stringify(data.settings))
      );
      storageService.setItem(
        STORAGE_KEYS.PREFERENCES,
        stringEncrypt(JSON.stringify(data.organization_preference))
      );
      storageService.setItem(
        STORAGE_KEYS.ORGANIZATION,
        stringEncrypt(JSON.stringify(data.organization))
      );
      setThemeColor();
      this.saveAuthInfo(
        user,
        data.permissions,
        data.organization_preference,
        data.settings,
        data.organization
      );

      this.$router.push(PATH.DASHBOARD);
    },
    clearStorage() {
      storageService.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
      storageService.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
      storageService.removeItem(STORAGE_KEYS.USER);
      storageService.removeItem(STORAGE_KEYS.PERMISSIONS);
      storageService.removeItem(STORAGE_KEYS.SETTINGS);
      storageService.removeItem(STORAGE_KEYS.ORGANIZATION);
      storageService.removeItem(STORAGE_KEYS.LAST_FETCHED_FEATURED_NEWS_TIME);
    },
    logout() {
      this.clearStorage();
      this.clearAuthInfo();
      clearCache();
      this.$router.replace(PATH.ROOT);
    },
    updatePreferences(preferences: IOrganizationPreference) {
      storageService.setItem(
        STORAGE_KEYS.PREFERENCES,
        stringEncrypt(JSON.stringify(preferences))
      );
      this.updateOrganizationPreference(preferences);
    },

    enableIsAuthenticating() {
      this.isAuthenticating = true;
    },
    setUser(user: IUserV2) {
      this.user = user;
    },
    setPermissions(permissions: Array<PERMISSIONS>) {
      this.permissions = permissions;
    },
    setSettings(settings: ISettings) {
      this.settings = settings;
    },
    disableIsAuthenticating() {
      this.isAuthenticating = false;
    },
    saveAuthInfo(
      user: IUserV2,
      permissions: Array<PERMISSIONS>,
      organizationPreference: IOrganizationPreference,
      settings: ISettings,
      organization: IOrganizationFromLoginResponse
    ) {
      this.user = user;
      this.permissions = permissions;
      this.organizationPreference = organizationPreference;
      this.settings = settings;
      this.organization = organization;
      this.isAuthenticating = false;
      this.isUserSignedIn = true;
    },
    clearAuthInfo() {
      this.user = null;
      this.settings = null;
      this.permissions = null;
      this.organization = null;
      this.isUserSignedIn = false;
    },
    updateOrganizationPreference(payload: IOrganizationPreference) {
      this.organizationPreference = payload;
    },
  },
  beforeMount() {
    authBus.on(AUTH_EVENTS.LOGIN, (data) => {
      this.login(data as LoginResponseModel);
    });
    authBus.on(AUTH_EVENTS.LOGOUT, () => {
      this.logout();
    });
    authBus.on(AUTH_EVENTS.UPDATE_PREFERENCES, (data) => {
      this.updatePreferences(data as IOrganizationPreference);
    });
  },

  provide() {
    return {
      enableIsAuthenticating: this.enableIsAuthenticating,
      setPermissions: this.setPermissions,
      setUser: this.setUser,
      clearAuthInfo: this.clearAuthInfo,
      setSettings: this.setSettings,
      disableIsAuthenticating: this.disableIsAuthenticating,
      updateOrganizationPreference: this.updateOrganizationPreference,
      user: () => this.user,
      settings: () => this.settings,
      permissions: () => this.permissions,
      organizationPreference: () => this.organizationPreference,
      organization: () => this.organization,
      isUserSignedIn: () => this.isUserSignedIn,
      isAuthenticating: () => this.isAuthenticating,
    };
  },
});
