import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_AgImage = _resolveComponent("AgImage")!
  const _component_AgIcon = _resolveComponent("AgIcon")!
  const _component_ToursList = _resolveComponent("ToursList")!

  return (_openBlock(), _createBlock(_component_ToursList, {
    "tour-type": _ctx.TOUR_TYPES.UMRAH,
    title: "UMRAH / ZIARAT PACKAGES"
  }, {
    tourSummary: _withCtx(({ tourItem }) => [
      _createVNode(_component_ag_div, { class: "overflow-x-scroll main_tour_info" }, {
        default: _withCtx(() => [
          _createVNode(_component_ag_div, { class: "inner_row" }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_div, { class: "inner_tour_info" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_div, { class: "head" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Includes")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ag_div, { class: "type_wrap" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tourItem.inclusions, (item, index) => {
                        return (_openBlock(), _createBlock(_component_ag_div, {
                          key: index,
                          class: "combine_logo"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AgImage, {
                              src: item.icon
                            }, null, 8, ["src"]),
                            _createVNode(_component_ag_div, { class: "type_value" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.category), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              (_ctx.hotelItems(tourItem).length > 0)
                ? (_openBlock(), _createBlock(_component_ag_div, {
                    key: 0,
                    class: "inner_tour_info"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ag_div, { class: "head" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Distance")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ag_div, { class: "type_wrap" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotelItems(tourItem), (item, index) => {
                            return (_openBlock(), _createBlock(_component_ag_div, {
                              key: index,
                              class: "combine_logo d-flex"
                            }, {
                              default: _withCtx(() => [
                                (item?.hotelItemDetails.propertyCity === 'Makkah')
                                  ? (_openBlock(), _createBlock(_component_AgIcon, {
                                      key: 0,
                                      class: "margin_right_10",
                                      name: "u_d_kaaba_icon"
                                    }))
                                  : _createCommentVNode("", true),
                                (item?.hotelItemDetails.propertyCity === 'Medina')
                                  ? (_openBlock(), _createBlock(_component_AgIcon, {
                                      key: 1,
                                      name: "u_d_madina_icon"
                                    }))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_ag_div, { class: "type_value" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item?.hotelItemDetails.distance), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_ag_div, { class: "inner_row" }, {
            default: _withCtx(() => [
              _createVNode(_component_ag_div, { class: "inner_tour_info" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ag_div, { class: "head" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Route Info")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ag_div, { class: "route_info_wrapper" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routeNames(tourItem.items), (routeName, index) => {
                        return (_openBlock(), _createBlock(_component_ag_div, {
                          key: index,
                          class: "route_info"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(routeName), 1)
                              ]),
                              _: 2
                            }, 1024),
                            (index !== _ctx.routeNames(tourItem.items).length - 1)
                              ? (_openBlock(), _createBlock(_component_AgIcon, {
                                  key: 0,
                                  name: "arrowRightGreen"
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["tour-type"]))
}