
import { defineComponent } from "vue";
import qs from "query-string";
import PriceSummary from "@/modules/FDG/components/PriceSummary.vue";
import { PAX_MAPPINGS } from "@/enums/PAX_MAPPINGS";
import { PAX_TYPES } from "@/ag-portal-common/constants/paxTypes";
import {
  formatNumber,
  formatWordIntoCapitalize,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import NATIONALITIES from "@/ag-portal-common/constants/nationalities";
import { PAX_RELATIONS } from "@/ag-portal-common/constants/paxRelations";
import { PAX_TITLES } from "@/ag-portal-common/constants/paxTitles";
import {
  FORMAT_DD_MMM,
  FORMAT_YYY_MM_DD,
} from "@/ag-portal-common/constants/dateTimeFormats";
import {
  IFDGBookingDetail,
  IFDGBookingDetailItem,
} from "@/interfaces/fdgBookingDetail.interface";
import { IPriceSummary } from "@/modules/FDG/interface/priceSummary.interface";
import TripSummary from "@/modules/FDG/components/TripSummary.vue";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";
import HotelSummary from "@/modules/FDG/components/HotelSummary.vue";
import _ from "lodash";

const travelerInitialValues = {
  passportNumber: "",
  isPassportImageRequired: false,
  passportImage: null,
  passportRequired: false,
  passportExpiry: null,
  dob: null,
  title: null,
  firstName: "",
  lastName: "",
  type: "",
  documents: [],
  nationality: null,
  passengerRelation: null,
};

interface IFileDocument {
  title: string;
  file: File;
}

export default defineComponent({
  name: "BookingDetails",
  computed: {
    renderStatusSeverity() {
      switch (_.capitalize(this.renderBookingStatus)) {
        case "CANCELLED":
          return "danger";
        case "CONFIRMED":
          return "primary";
        case "ISSUED":
          return "primary";
        default:
          return "primary";
      }
    },
    isFetchingFDGBookingDetail(): boolean {
      return this.$store.getters.isFetchingFDGBookingDetail;
    },
    renderBookingId() {
      return this.$route.params.id;
    },
    renderBookingStatus() {
      return this.$store.getters.fdgBookingDetail.status;
    },
    BOOKING_TYPES() {
      return BOOKING_TYPES;
    },
    TOUR_TYPES() {
      return TOUR_TYPES;
    },
    PAX_RELATIONS() {
      return PAX_RELATIONS;
    },
    fdgBookingDetail(): IFDGBookingDetail {
      return this.$store.getters.fdgBookingDetail;
    },
    isConfirmFDGBooking() {
      return this.$store.getters.isConfirmFDGBooking;
    },
    isConfirmFDGBookingWithoutTravelerDetails() {
      return this.$store.getters.isConfirmFDGBookingWithoutTravelerDetails;
    },
    PAX_TYPES() {
      return PAX_TYPES;
    },
    NATIONALITIES() {
      return NATIONALITIES;
    },
    getBookingId(): IFDGBookingDetail {
      return this.$store.getters.fdgBookingDetail.bookingId;
    },
    getLegs(): IFDGBookingDetailItem[] {
      let fdgBookingDetail: IFDGBookingDetail =
        this.$store.getters.fdgBookingDetail;
      return fdgBookingDetail?.items || [];
    },
    getPriceInfo(): IPriceSummary[] {
      let fdgBookingDetail: IFDGBookingDetail | null =
        this.$store.getters.fdgBookingDetail;
      let startDate = fdgBookingDetail?.startDate || new Date();

      return [
        {
          info: `${getFormattedDateTime(startDate, FORMAT_DD_MMM)} | ${
            fdgBookingDetail?.items[0].itemName
          }`,
          amount: `${fdgBookingDetail?.currency} ${formatNumber(
            fdgBookingDetail?.bookingPricePerPerson || ""
          )}`,
          isTotalPriceCell: false,
        },
        {
          info: `Passenger(s) Count x ${fdgBookingDetail?.numberOfGuests}`,
          amount: `${fdgBookingDetail?.currency} ${formatNumber(
            fdgBookingDetail?.totalPrice || ""
          )}`,
          isTotalPriceCell: false,
        },
        {
          info: "PRICE YOU PAY",
          amount: `${fdgBookingDetail?.currency} ${formatNumber(
            fdgBookingDetail?.totalPrice || ""
          )}`,
          isTotalPriceCell: true,
        },
      ];
    },
  },
  components: { HotelSummary, TripSummary, PriceSummary },
  data(): {
    formData: any;
    errors: any;
    enableFormFilling: boolean;
  } {
    return {
      enableFormFilling: false,
      errors: {},
      formData: {
        tourType: TOUR_TYPES.FLIGHTS_ONLY,
        bookingCustomer: {
          phone: "",
          email: "",
        },
        passengers: [],
      },
    };
  },
  methods: {
    formatWordIntoCapitalize,
    PAX_TITLES(type: PAX_MAPPINGS) {
      return PAX_TITLES.filter((item) => item.type === (type as string));
    },
    formattedDate(date: Date | null) {
      if (date) {
        return getFormattedDateTime(date, FORMAT_YYY_MM_DD);
      }
      return "";
    },
  },
  async beforeMount() {
    await this.$store.dispatch(
      "fetchGroupFlightBookingDetail",
      this.$route.params.id
    );
    let fdgBookingDetail: IFDGBookingDetail =
      this.$store.getters.fdgBookingDetail;
    this.enableFormFilling =
      fdgBookingDetail?.tourType !== TOUR_TYPES.FLIGHTS_ONLY;
    let parsedQuery = qs.parse(window.location.search) as {
      adult: string;
      infant: string;
      child: string;
    };
    let stringedZero = "0";
    let adultCount = parseInt(parsedQuery?.adult || stringedZero);
    let childCount = parseInt(parsedQuery?.child || stringedZero);
    let infantCount = parseInt(parsedQuery?.infant || stringedZero);
    let totalCount = adultCount + childCount + infantCount;
    let passengers: Array<any> = [];
    for (let i = 0; i < totalCount; i++) {
      let paxType = "";
      if (adultCount) {
        paxType = PAX_MAPPINGS.ADULT;
        adultCount--;
      } else if (childCount) {
        paxType = PAX_MAPPINGS.CHILD;
        childCount--;
      } else {
        paxType = PAX_MAPPINGS.INFANT;
        infantCount--;
      }
      let passenger = {
        ...travelerInitialValues,
        type: paxType,
        passportRequired: fdgBookingDetail?.passportRequired || false,
        isPassportImageRequired:
          fdgBookingDetail?.passportRequired &&
          fdgBookingDetail.tourType === TOUR_TYPES.UMRAH,
        documents:
          fdgBookingDetail?.additionalDocuments.map((doc: IFileDocument) => ({
            title: doc,
            file: null,
          })) || [],
      };
      passengers.push(passenger);
    }
    this.formData.tourType = fdgBookingDetail.tourType;
    this.formData.passengers = passengers;
  },
});
