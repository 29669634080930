import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_ag_hotel_selected_info = _resolveComponent("ag-hotel-selected-info")!
  const _component_AgTextarea = _resolveComponent("AgTextarea")!
  const _component_ag_modal = _resolveComponent("ag-modal")!

  return (_openBlock(), _createBlock(_component_ag_modal, {
    "is-open": _ctx.isOpen,
    modalWidth: "70%"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createTextVNode("Selected Hotel for FIT")
        ]),
        _: 1
      }),
      _createVNode(_component_a_g_button, {
        type: "button",
        class: "modal_close_icon",
        variant: "link",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Close")
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      (_ctx.emptyHaiKNai > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAllBookingsToShow(), (booking, bookingKey) => {
              return (_openBlock(), _createElementBlock("div", { key: bookingKey }, [
                _createVNode(_component_ag_hotel_selected_info, {
                  checkinDate: booking.checkInDate,
                  checkoutDate: booking.checkOutDate,
                  noOfNight: booking.noOfNight,
                  totalCost: booking.totalCost,
                  hotelName: booking.hotelName,
                  roomType: booking.roomType,
                  travelers: booking.travellers
                }, null, 8, ["checkinDate", "checkoutDate", "noOfNight", "totalCost", "hotelName", "roomType", "travelers"])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCurrentBooking(), (booking, bookingKey) => {
              return (_openBlock(), _createElementBlock("div", { key: bookingKey }, [
                _createVNode(_component_ag_hotel_selected_info, {
                  checkinDate: booking.checkInDate,
                  checkoutDate: booking.checkOutDate,
                  noOfNight: booking.noOfNight,
                  totalCost: booking.totalCost,
                  hotelName: booking.hotelName,
                  roomType: booking.roomType,
                  travelers: booking.travellers
                }, null, 8, ["checkinDate", "checkoutDate", "noOfNight", "totalCost", "hotelName", "roomType", "travelers"])
              ]))
            }), 128))
          ])),
      _createVNode(_component_AgTextarea, {
        rows: "3",
        label: "Type Your Query",
        modelValue: _ctx.query,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query) = $event)),
        value: _ctx.query
      }, null, 8, ["modelValue", "value"])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_a_g_button, {
        type: "button",
        class: "mr-10 text-uppercase",
        onClick: _ctx.getAllBookingToEmail
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Add Next Itinerary")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_g_button, {
        type: "button",
        variant: "outlined",
        onClick: _ctx.sendEmail,
        class: "text-uppercase"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Continue by sending email")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["is-open"]))
}