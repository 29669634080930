import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgCheckbox = _resolveComponent("AgCheckbox")!
  const _component_AgFilterDropdown = _resolveComponent("AgFilterDropdown")!
  const _component_AgCard = _resolveComponent("AgCard")!
  const _component_ag_loader = _resolveComponent("ag-loader")!
  const _component_ag_div = _resolveComponent("ag-div")!
  const _component_ag_column = _resolveComponent("ag-column")!
  const _component_a_g_button = _resolveComponent("a-g-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ag_row = _resolveComponent("ag-row")!
  const _component_ag_card = _resolveComponent("ag-card")!
  const _component_ag_not_found = _resolveComponent("ag-not-found")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_heading, {
      title: _ctx.title,
      class: "bg_heading_wrap",
      variant: "h1"
    }, null, 8, ["title"]),
    (!_ctx.isUmrah())
      ? (_openBlock(), _createBlock(_component_AgCard, {
          key: 0,
          class: "flightFilterWrap"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AgDiv, { class: "head" }, {
              default: _withCtx(() => [
                _createTextVNode(" Filter ")
              ]),
              _: 1
            }),
            _createVNode(_component_AgDiv, { class: "d-flex" }, {
              default: _withCtx(() => [
                _createVNode(_component_AgFilterDropdown, { label: "Currency" }, {
                  Items: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uniqueCurrencies, (currency) => {
                      return (_openBlock(), _createBlock(_component_AgCheckbox, {
                        onClick: _cache[0] || (_cache[0] = (e) => e.stopPropagation()),
                        key: currency,
                        value: currency,
                        label: currency,
                        modelValue: _ctx.selectedCurrencies,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCurrencies) = $event))
                      }, null, 8, ["value", "label", "modelValue"]))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgFilterDropdown, { label: "Airlines" }, {
                  Items: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uniqueAirlines, (airlines) => {
                      return (_openBlock(), _createBlock(_component_AgCheckbox, {
                        key: airlines,
                        value: airlines,
                        label: airlines,
                        modelValue: _ctx.selectedAirlines,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedAirlines) = $event))
                      }, null, 8, ["value", "label", "modelValue"]))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgFilterDropdown, { label: "Departure City" }, {
                  Items: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uniqueCities, (city) => {
                      return (_openBlock(), _createBlock(_component_AgCheckbox, {
                        key: city,
                        value: city,
                        label: city,
                        modelValue: _ctx.selectedCities,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedCities) = $event))
                      }, null, 8, ["value", "label", "modelValue"]))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createVNode(_component_AgFilterDropdown, { label: "Departure Month" }, {
                  Items: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uniqueMonths, (month) => {
                      return (_openBlock(), _createBlock(_component_AgCheckbox, {
                        key: month,
                        value: month,
                        label: month,
                        modelValue: _ctx.selectedMonths,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedMonths) = $event))
                      }, null, 8, ["value", "label", "modelValue"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isFetchingFDGList)
      ? (_openBlock(), _createBlock(_component_ag_loader, { key: 1 }))
      : (!_ctx.isFetchingFDGList && _ctx.fdgList.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.finalList, (fdg) => {
            return (_openBlock(), _createBlock(_component_ag_card, {
              key: fdg.id,
              class: "margin_top_20 relative"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ag_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ag_column, {
                      md: "9",
                      sm: "12",
                      cols: "12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_heading, {
                          title: fdg.title,
                          class: "tour_heading_wrap",
                          variant: "h2"
                        }, null, 8, ["title"]),
                        _createVNode(_component_ag_div, { class: "main_tour_wrapper" }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", {
                              src: 
              fdg.mainImage || 'https://cdn.net3000.ca/images/192x30/hajj.jpg'
            ,
                              alt: "img",
                              width: "220",
                              height: "152",
                              class: "image_wrapper"
                            }, null, 8, _hoisted_1),
                            _renderSlot(_ctx.$slots, "tourSummary", { tourItem: fdg })
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ag_column, {
                      md: "3",
                      sm: "12",
                      cols: "12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ag_div, { class: "price_info_wrap_group" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ag_div, { class: "available_seat_wrap" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ag_div, { class: "inner_wrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(fdg.bookingInfo.remainingQuantity) + " Seats Available ", 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ag_div, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ag_div, { class: "start_form" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Starting From")
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ag_div, { class: "price_details_wrap" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ag_div, { class: "inner_price" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(`${fdg.bookingInfo.currency} ${_ctx.formatNumber(
                    fdg.bookingInfo.price
                  )}`), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ag_div, { class: "price_details_info" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" Per Passenger ")
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ag_div, { class: "margin_top_20 text-center" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_router_link, {
                                  to: _ctx.routToDetailPage(fdg.id)
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_g_button, {
                                      class: "full-width",
                                      variant: "primary"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(" VIEW DETAILS")
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["to"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        : (_openBlock(), _createBlock(_component_ag_not_found, {
            key: 3,
            description: _ctx.NOTIFICATION_MESSAGES.NO_RESULTS_FOUND
          }, null, 8, ["description"]))
  ], 64))
}