import { IFDGState } from "@/interfaces/fdgState.interface";

const fdgState: IFDGState = {
  fdgList: [],
  isFetchingFDGList: false,
  fdgDetail: null,
  isFetchingFDGDetail: false,
  fdgBookingDetail: null,
  isFetchingFDGBookingDetail: false,
  isInitiatingFDGBooking: false,
  isConfirmFDGBooking: false,
  isConfirmFDGBookingWithoutTravelerDetails: false,
};

export default fdgState;
