
import { defineComponent } from "vue";
import ToursList from "@/modules/FDG/components/ToursList.vue";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_DD_MMM_YYYY } from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "GroupFlightsList",
  computed: {
    TOUR_TYPES() {
      return TOUR_TYPES;
    },
  },
  methods: {
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY);
    },
  },
  components: { ToursList },
});
