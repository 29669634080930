
import { defineComponent } from "vue";

export default defineComponent({
  name: "DurationSegment",
  props: {
    duration: { type: Number, required: true },
    boldNums: { type: Boolean, default: false },
    title: { type: String, required: true },
  },
});
