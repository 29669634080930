import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_heading = _resolveComponent("ag-heading")!
  const _component_AgPriceSummary = _resolveComponent("AgPriceSummary")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ag_heading, {
      class: "margin_bottom_10",
      title: "Price Summary",
      variant: "h2"
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceInfoCells, (item, index) => {
      return _withDirectives((_openBlock(), _createBlock(_component_AgPriceSummary, {
        key: index,
        info: item.info,
        price: item.amount,
        "total-price": item.isTotalPriceCell,
        "test-id": ""
      }, null, 8, ["info", "price", "total-price"])), [
        [_vShow, true]
      ])
    }), 128)),
    _renderSlot(_ctx.$slots, "flightDetailsActionButtons")
  ], 64))
}