import { createStore, ModuleTree } from "vuex";
import fdgModule from "@/modules/FDG/vuex";

const modules: ModuleTree<any> = {
  fdgModule,
};

export default createStore({
  modules,
});
