import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TourDetail = _resolveComponent("TourDetail")!

  return (_openBlock(), _createBlock(_component_TourDetail, {
    "pax-selection-description": 
      _ctx.FDG_DETAIL_VIEW_TEXTS.GROUP_FLIGHT_PAX_SELECTION_DESCRIPTION
    ,
    "pax-selection-heading": 
      _ctx.FDG_DETAIL_VIEW_TEXTS.GROUP_FLIGHT_PAX_SELECTION_HEADING
    
  }, null, 8, ["pax-selection-description", "pax-selection-heading"]))
}