export const FDG_FLIGHTS_API_PATH = {
  GET: "/v1/tours",
  GET_BY_ID: "/v1/tours/{0}/",
  GET_BOOKING_BY_ID: "/v2/bookings/{0}/",
  POST_INITIATE_BOOKING: "/v1/tours/bookings/initiate/",
  POST_CONFIRM_BOOKING: "/v1/tours/bookings/confirm/",
  POST_CONFIRM_BOOKING_WITHOUT_TRAVELER_DETAILS:
    "/v1/tours/bookings/democonfirm/",
};

export const FDG_DETAIL_VIEW_TEXTS = {
  GROUP_FLIGHT_PAX_SELECTION_HEADING: "Passenger(s) Count",
  GROUP_FLIGHT_PAX_SELECTION_DESCRIPTION:
    "Please select number of Passenger(s) for the chosen package",
  UMRAH_PAX_SELECTION_HEADING: "Pilgrim(s) Count",
  UMRAH_PAX_SELECTION_DESCRIPTION:
    "Please select number of Pilgrim(s) for the chosen package",
};
