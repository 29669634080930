import { MutationTree } from "vuex";
import { IFDGState } from "@/interfaces/fdgState.interface";
import { IFDG } from "@/interfaces/fdg.interface";
import { IFDGDetail } from "@/interfaces/fdgDetail.interface";
import { IFDGBookingDetail } from "@/interfaces/fdgBookingDetail.interface";

const mutations: MutationTree<IFDGState> = {
  enableIsFetchingGroupFlights(state) {
    state.isFetchingFDGList = true;
  },
  saveGroupFlights(state, payload: Array<IFDG>) {
    state.isFetchingFDGList = false;
    state.fdgList = payload;
  },
  enableIsFetchingGroupFlightDetail(state) {
    state.isFetchingFDGDetail = true;
  },
  saveGroupFlightDetail(state, payload: IFDGDetail) {
    state.isFetchingFDGDetail = false;
    state.fdgDetail = payload;
  },
  enableIsFetchingGroupFlightBookingDetail(state) {
    state.isFetchingFDGBookingDetail = true;
  },
  saveGroupFlightBookingDetail(state, payload: IFDGBookingDetail) {
    state.isFetchingFDGBookingDetail = false;
    state.fdgBookingDetail = payload;
  },
  enableIsInitiatingGroupFlightBooking(state) {
    state.isInitiatingFDGBooking = true;
  },
  disableIsInitiatingGroupFlightBooking(state) {
    state.isInitiatingFDGBooking = false;
  },
  enableIsConfirmGroupFlightBooking(state) {
    state.isConfirmFDGBooking = true;
  },
  disableIsConfirmGroupFlightBooking(state) {
    state.isConfirmFDGBooking = false;
  },
  enableIsConfirmGroupFlightBookingWithoutTravelerDetails(state) {
    state.isConfirmFDGBookingWithoutTravelerDetails = true;
  },
  disableIsConfirmGroupFlightBookingWithoutTravelerDetails(state) {
    state.isConfirmFDGBookingWithoutTravelerDetails = false;
  },
};

export default mutations;
