
import { defineComponent, PropType } from "vue";
import { IFDGItem } from "@/interfaces/fdg.interface";
import {
  getDayFromDate,
  getDurationText,
  getFormattedDateTime,
  hotelNumberOfNightStayText,
} from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_HH_SS_A,
  FORMAT_MMMM_DD_YYYY,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { DAYS } from "@/ag-portal-common/enums/DAYS";

export default defineComponent({
  name: "HotelItenary",
  props: {
    ietnaryDetail: {
      type: Object as PropType<IFDGItem>,
    },
  },
  methods: {
    formattedTime(date: Date) {
      return getFormattedDateTime(date, FORMAT_HH_SS_A);
    },
    formattedDayAndDate(date: Date) {
      return (
        DAYS[getDayFromDate(date)] +
        ", " +
        getFormattedDateTime(date, FORMAT_MMMM_DD_YYYY)
      );
    },
    getDurationText,
  },
  computed: {
    numberOfNights() {
      let _checkInDate = new Date(
        this.ietnaryDetail?.hotelItemDetails?.checkIn || Date.now()
      );
      let _checkOutDate = new Date(
        this.ietnaryDetail?.hotelItemDetails?.checkOut || Date.now()
      );
      return hotelNumberOfNightStayText(_checkInDate, _checkOutDate);
    },
  },
});
