
import { defineComponent, PropType } from "vue";
import {
  genTestId,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { ELEMENT_TYPES } from "@/ag-portal-common/enums/ELEMENT_TYPES";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { FORMAT_DD_MMM_YYYY_HH_SS_A } from "@/ag-portal-common/constants/dateTimeFormats";

export default defineComponent({
  name: "NotificationPopup",
  props: {
    showModal: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    actionButtonText: {
      type: String,
    },
    type: {
      type: Object as PropType<NOTIFICATION_TYPES>,
    },
  },
  computed: {
    ELEMENT_TYPES() {
      return ELEMENT_TYPES;
    },

    currentDate() {
      let date = new Date();
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY_HH_SS_A);
    },
  },
  methods: {
    genTestId,
    onClose() {
      this.$emit("onClose");
    },
  },
  data() {
    return {
      isOpen: this.showModal,
    };
  },
});
