
import { defineComponent } from "vue";
import ToursList from "@/modules/FDG/components/ToursList.vue";
import { TOUR_TYPES } from "@/enums/TOUR_TYPES";
import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import { FORMAT_DD_MMM_YYYY } from "@/ag-portal-common/constants/dateTimeFormats";
import { IFDG, IFDGItem } from "@/interfaces/fdg.interface";
import { BOOKING_TYPES } from "@/ag-portal-common/enums/BOOKING_TYPES";

export default defineComponent({
  name: "UmrahList",
  computed: {
    TOUR_TYPES() {
      return TOUR_TYPES;
    },
  },
  methods: {
    hotelItems(fdg: IFDG) {
      return fdg.items.filter((item) => item.itemType === BOOKING_TYPES.HOTEL);
    },
    formattedDate(date: Date) {
      return getFormattedDateTime(date, FORMAT_DD_MMM_YYYY);
    },
    routeNames(tourItems: IFDGItem[]) {
      return tourItems
        .map((tourItem) => {
          if (tourItem.itemType === BOOKING_TYPES.FLIGHT)
            return [
              tourItem.flightItemDetails?.originCity,
              tourItem.flightItemDetails?.destinationCity,
            ];
          else if (tourItem.itemType === BOOKING_TYPES.HOTEL)
            return [tourItem.hotelItemDetails?.propertyCity];
          else return [];
        })
        .join(",")
        .split(",");
    },
  },
  components: { ToursList },
});
